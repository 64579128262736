body {
    font-family: Arial, sans-serif;
    background-color: #f5f5f5;
    margin: 0;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

h2 {
    font-size: 24px;
    font-weight: bold;
}

p {
    font-size: 16px;
    margin: 10px 0;
}

input[type="number"] {
    padding: 5px;
    font-size: 16px;
    margin-right: 10px;
}

button {
    padding: 10px;
    font-size: 16px;
    margin-right: 10px;
    cursor: pointer;
}

.betting-container {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 300px;
    text-align: center;
    margin: 20px 0;
    position: relative;
}


.betting-option-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.betting-header, .betting-outcome {
    font-size: 18px;
    margin-bottom: 10px;
    font-weight: bold;
}

.betting-options {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
}

.betting-option {
    background-color: #e0e0e0;
    border: none;
    border-radius: 20px;
    padding: 10px 40px;
    font-size: 16px;
    cursor: pointer;
    outline: none;
}

.odds-label.yes {
    margin-bottom: 10px;
    font-size: 16px;
    color: #777;
    font-weight: bold;  
}

.odds-label.no {
    margin-bottom: 10px;
    font-size: 16px;
    color: #777;
    font-weight: bold;  
}

.odds-label.yes.active {
    color: #60AA69;  /* 綠色 */
}

.odds-label.no.active {
    color: #CA5724;  /* 紅色 */
}

.betting-option.active {
    background-color: #60AA69;
    color: white;
}

.betting-option.active.no {
    background-color: #CA5724;
}

.betting-amount-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    flex-direction: column;
}

.betting-amount {
    display: flex;
    align-items: center;
    border: 1px solid #ddd;
    border-radius: 5px;
    width: 100%;
    max-width: 100%;
    justify-content: space-between;
}

.amount-control {
    background-color: #e0e0e0;
    border: none;
    padding: 10px;
    font-size: 18px;
    cursor: pointer;
    border-radius: 0;
    outline: none;
    flex: 0 0 50px;
    margin: 0;
}

.amount-control:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.amount-control:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.betting-amount input[type="text"] {
    text-align: center;
    font-size: 16px;
    border: none;
    outline: none;
    width: 50px;
    margin: 0;
    flex: 1;
}

.bet-button {
    background-color: #60AA69;
    color: white;
    border: none;
    border-radius: 10px;
    padding: 15px;
    font-size: 16px;
    cursor: pointer;
    width: 100%;
    text-transform: uppercase;
    font-weight: bold;
}

.bet-button .to-win {
    font-size: 14px;
    margin-top: 5px;
}

.bet-button.no {
    background-color: #CA5724;
}

.bet-button:hover {
    background-color: #60AA69;
}

.bet-button.no:hover {
    background-color: #CA5724;
}

.error {
    color: #CA5724;
    font-size: 14px;
    margin-top: 5px;
}

.pot-container {
    background-color: white; /* 新增 */
    border-radius: 10px; /* 新增 */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* 新增 */
    padding: 20px; /* 新增 */
    width: 300px; /* 新增 */
    text-align: center; /* 新增 */
    margin: 20px 0; /* 新增 */
    position: relative; /* 新增 */
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.option {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.option .label {
    font-size: 14px;
    color: #777;
}

.option .amount {
    font-size: 16px;
    font-weight: bold;
    margin-top: 5px;
}

.yes-option .amount {
    color: #60AA69;
}

.no-option .amount {
    color: #CA5724;
}

.yes-option .label {
    color: #388e3c; /* 新增 */
    font-weight: bold; /* 新增 */
}

.no-option .label {
    color: #d32f2f; /* 新增 */
    font-weight: bold; /* 新增 */
}

.divider {
    width: 1px;
    background-color: #ddd;
    height: 40px;
    align-self: center;
}

.loading-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(245, 245, 245);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.loading-content {
    background-color: white;
    padding: 20px 40px;
    border-radius: 20px;
    text-align: center;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
}

.loading-text {
    font-size: 20px;
    font-weight: bold; 
    color: #28a745; 
}

.success-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(245, 245, 245);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.success-content {
    background-color: white;
    padding: 20px 40px;
    border-radius: 20px;
    text-align: center;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
}

.success-text {
    font-size: 20px;
    font-weight: bold; 
    color: #28a745; 
}

/* 圓形黃色幫助按鈕樣式 */
.help-button {
    background-color: #D2B87C;
    color: white;
    border: none;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    font-size: 24px;
    font-weight: bold;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed; /* 固定位置 */
    bottom: 20px; /* 底部距離 */
    right: 20px; /* 右邊距離 */
    z-index: 1000; /* 確保按鈕位於頁面頂部 */
}

/* 幫助提示卡樣式 */
.help-card {
    display: none; /* 初始狀態隱藏 */
    position: fixed;
    bottom: 80px; /* 放置在幫助按鈕上方 */
    right: 20px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    padding: 20px;
    max-width: 300px;
    z-index: 1000;
}

/* 顯示卡片時的樣式 */
.help-card.show {
    display: block; /* 顯示卡片 */
}

/* 卡片內標題和內容的樣式 */
.help-card h4 {
    margin: 0;
    font-size: 18px;
    color: #333;
}

.help-card p {
    font-size: 14px;
    margin: 10px 0 0;
    color: #555;
}

.help-card h5 {
    margin-top: 10px;
    font-size: 16px;
    color: #444;
}

